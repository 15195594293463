@import "styles/fonts/fonts";
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.app {
  font-family: 'Avenir Next', sans-serif;
}

.container {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
}

.header {
  padding-top: 40px;
  position: relative;

  svg {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: " ";
    width: 759px;
    height: 729px;
    background: url("./assets/dec.png") no-repeat;
    background-size: contain;
    top: -82px;
    left: -333px;
  }
}

.main {
  margin-top: 38px;
  &_column {
    display: flex;
    width: 100%;

    &-left {
      width: 40%;
      text-align: center;
    }
    &-right {
      width: 60%;
    }
  }
  &-label-wrapper {
    position: relative;
    margin-left: -16px;
    margin-bottom: 10px;
    svg {
      position: absolute;
      top: 26px;
      left: -8px;
    }
  }
  &-img {
    max-width: 418px;
  }
  &-count-page {
    color: #657795;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 7px 20px;
    border: 1px solid rgba(101, 119, 149, 0.3);
    border-radius: 4px;

    span {
      font-size: 12px;
    }
  }
  &-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    color: #050F19;
    text-align: center;
    letter-spacing: 0.02em;
    span {
      color: #1452F0;
    }
  }
  &-title-text {
    font-weight: 700;
    font-size: 49px;
    line-height: 53px;
    color: #050F19;
    display: block;
    margin-left: 12px;
  }
  &-text-link {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #373F47;
    display: block;
    margin-top: 29px;
    margin-left: 26px;

    a {
      text-decoration: none;
      color: #1452F0;
    }
  }
  &-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #373F47;
    margin-top: 24px;
    display: block;
    margin-left: 26px;
  }
  &-description-bolt {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: block;
    margin-left: 26px;
  }
  &-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #373F47;
    margin-top: 28px;
    display: block;
    margin-left: 26px;
  }

  .share {
    margin-top: 20px;
    margin-left: 25px;
    display: flex;
    align-items: center;

    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #151B1F;
      background: #FFFFFF;
      border: 1px solid #EDEFF1;
      border-radius: 6px;
      padding: 11px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.form {
  margin-top: 27px;
  margin-left: 13px;
  max-width: 650px;
  input {
    border: 1px solid #EDEFF1;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 18px 12px;
    color: #657795;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    max-width: 310px;
    width: 100%;

    &:first-child {
      margin-right: 16px;
    }
  }
  .form-input-wrapper {
    display: flex;
    width: 100%;
    span {
      max-width: 310px;
      width: 100%;
      display: block;
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .button {
    background: #1452F0;
    box-shadow: 0px 10px 20px rgba(11, 97, 51, 0.2);
    border-radius: 8px;
    padding: 18px 0 17px;
    width: 100%;
    margin-top: 20px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    border: none;
    cursor: pointer;
  }

  .disable {
    background: #C3CAD1;
    color: #3E4247;
    cursor: not-allowed;
  }

  .status-thx {
    color: #1452F0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }
}

.footer {
  margin-top: 170px;
  background: url("./assets/bg.png");

  &_wrapper {
    display: flex;
  }

  &-img {
    max-width: 485px;
    margin-top: -111px;
    margin-left: -52px;
  }

  &-links {
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 86px;
    margin-right: 58px;
    column-gap: 49px;
  }
  &-link {
    text-align: center;
    span {
      display: block;
    }
    &_text {
      color: #FFFFFF;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      margin-top: 4px;
    }
  }
}
.error {
  color: #FF570A;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.black {
  color: #050F19 !important;
}

@media screen and(max-width: 991px){
  .error {
    margin-top: 0px;
  }
  .container {
    padding: 0 20px;
  }
  .header {
    text-align: center;
    &:before {
      width: 563px;
      height: 593px;
      top: -53px;
      left: -233px;
    }
  }
  .main {
    margin-top: 16px;
    &_column {
      flex-direction: column;
      &-left, &-right {
        width: 100%;
      }
    }
    &-label-wrapper {
      margin-left: 19px;

      svg {
        max-width: 90px;
        top: -6px;
        left: -12px;
      }
    }
    &-img {
      max-width: 309px;
      margin: 0 auto 27px;
    }
    &-title {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      text-align: left;
      margin-top: 37px;
    }
    &-title-text {
      font-weight: 700;
      font-size: 25px;
      line-height: 27px;
      margin-left: 0;
    }
    &-text-link {
      margin-left: 0;
      margin-top: 19px;
    }
    &-description {
      margin-left: 0;
      margin-top: 16px;
    }
    &-description-bolt {
      margin-left: 0;
    }
    &-text {
      margin-left: 0;
    }
    .for-desc {
      display: none;
    }
    .share {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      margin-left: 0;
      button {
        margin-right: 0;
        width: 100%;
      }
    }
    .react-share__ShareButton {
      width: 49%;
    }
  }
  .form {
    margin-left: 0;
    margin-top: 17px;
    input {
      max-width: 100%;
      margin-bottom: 14px;
    }
    .button {
      margin-top: -3px;
    }
  }
  .form-input-wrapper {
    flex-direction: column;
  }
  .form .form-input-wrapper span {
    max-width: 100%;
  }
  .form .form-input-wrapper span:first-child {
    margin: 0;
  }
  .footer {
    margin-top: 102px;
    &-links {
      display: flex;
      flex-wrap: wrap;
      margin-top: 63px;
      margin-right: 0;
      justify-content: center;
      column-gap: 69px;
    }
    &_wrapper {
      flex-direction: column;
    }
    &-img {
      margin: -69px auto 0;
    }
    &-link {
      margin-bottom: 63px;
      display: block;
    }
  }

}
