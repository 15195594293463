@font-face {
  font-family: 'Avenir Next';
  src: url('https://files.defiyield.app/mail-book/fonts/6149672/9548fa3a-05c3-47ef-a6d5-f2f6fef54f84.woff2') format('woff2'),
    url('https://files.defiyield.app/mail-book/fonts/6149672/7667f1c1-c3ca-4520-aead-66f9d93d55a0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://files.defiyield.app/mail-book/fonts/6149639/11ba579d-59f5-479e-b2dd-411ca230f60c.woff2') format('woff2'),
    url('https://files.defiyield.app/mail-book/fonts/6149639/f740c173-ce1a-4e47-a4e3-fab0a45da815.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
